import React, { useState, useEffect } from 'react';
import API from '../../../../libs/api-lib';
import { Button, Form } from 'semantic-ui-react';
import { CHECKBOX, FORM_TOGGLE } from '../../../../components/cmp_form/cmp_form';
import Processing from '../../../../components/cmp_processing';

import './crd_features.css';

export default function CRD_FEATURES() {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_mode, set_mode ] = useState('LOADING');
    const [ var_features, set_features ] = useState([]);
    const [ var_working_features, set_working_features ] = useState([]);
    const [ var_processing, set_processing ] = useState(false);

    const feature_list = [
        {
            feature: 'networks',
            name: 'Affiliate network',
            info: 'Establish a network of connected organizations to enable sharing of assignments, resources, and related items.'
        },
        {
            feature: 'assignments',
            name: 'Assignments',
            info: 'Assignments allow for the grouping of individuals, requirements, documents, and other related items.',
            sub_features: [
                {
                    feature: 'dailycheck',
                    name: 'Daily check',
                    info: 'Present a daily questionnaire that users are required to complete.'
                },
                {
                    feature: 'r2w',
                    name: 'Onsite',
                    info: 'Enable individuals to declare their physical presence on a work site.'
                },
                {
                    feature: 'qraccess',
                    name: 'QR access',
                    info: 'Enable individuals to join an assignment by scanning a QR code.'
                },
            ]
        },
        {
            feature: 'credentials',
            name: 'Credential visibility',
            info: "Admins can view an individual's credentials from either the worker's profile or an assignment."
        },
        { feature: 'documents', name: 'Documents', info: 'Upload documents to be acknowledged by individuals.' },
        { feature: 'guest', name: 'Guest access', info: "Allow individuals to join an organization as a 'Guest.'" },
        { feature: 'identity_verification', name: 'Identity verification', info: 'Organizations can perform identity verifications.' },
        {
            feature: 'issuer',
            name: 'Issuer',
            info: 'Organizations can issue credentials',
            sub_features: [
                { 
                    feature: 'credit_tracking', 
                    name: 'Credit tracking credentials', 
                    info: 'Organizations can issue credit tracking credentials.' 
                },
                {
                    feature: 'verifiable_credentials',
                    name: 'Verifiable credentials',
                    info: 'Organizations can issue credentials as verifiable credentials.'
                }
            ]
        },
        { feature: 'mfa', name: 'MFA', info: 'Enable multifactor authentication for organizations.' },
        { feature: 'reports', name: 'Reports', info: 'Enable reports for organizations.' },
        { feature: 'requirements', name: 'Requirements', info: 'Monitor compliance of individuals with credential requirements and packages.' },
        { feature: 'vc-wallet', name: 'VC Wallet', info: 'Enable support for verifiable credentials in the cloud wallet.' },
        { feature: 'verification_templates', name: 'Verification templates', info: 'Organizations can create verification templates for VC verification.' },
    ];



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_record();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    //  functions ------------------------------------------------------------------------------------------------------

    async function populate_record() {
        set_mode('VIEW');
        try {
            set_features(await API_get_features());
        } catch (error) {
            console.error(error);
        }
    }

    async function save_record() {
        set_processing(true);
        try {
            await API_put_features();
            set_features(var_working_features.map(item => ({...item})));
        } catch (error) {
            console.error(error);
        } finally {
            set_mode('VIEW');
            set_processing(false);
        }
    }



    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_features() {
        return API.get('alpha-admin', '/get-features');
    }

    function API_put_features() {
        return API.put('alpha-admin', '/put-features', {
            body: var_working_features
        });
    }



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        set_working_features(var_features.map(item => ({...item})));
        set_mode('EDIT');
    }

    function onClick_cancel() {
        set_mode('VIEW');
    }

    function onChange_feature(feature_name) {
        let working_features = var_working_features.map(item => ({...item}));
        let feature = working_features.find(item => item.name === feature_name);
        feature.status = feature.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        if (feature.status === 'INACTIVE') {
            // Disable sub features whose parents become disabled
            let sub_features = feature_list.find(item => item.feature === feature_name)?.sub_features;
            sub_features?.forEach(sub_feature => {
                working_features.find(item => item.name === sub_feature.feature).status = 'INACTIVE';
            });
        }
        set_working_features(working_features);
    }

    function onClick_save() {
        if (var_processing) return;
        save_record();
    }



    // RENDER APP ======================================================================================================

    return (
        <>

            <div className='card__header'>
                <div className='card__header__left text'>
                    <div className='text--xl-medium'>Product Features</div>
                    <span className='text--sm-regular'>
                        <div style={{ display: 'inline' }}>Enable or disable the features in Alpha. Selections made here will impact&nbsp;</div>
                        <div className='text--sm-bold' style={{ display: 'inline' }}>all clients</div>
                        <div style={{ display: 'inline' }}>&nbsp;using Alpha.</div>
                    </span>
                </div>
                <div className='card__header__right'>
                    {var_mode === 'VIEW' &&
                        <Button className='secondary' onClick={() => onClick_edit()}>Edit</Button>
                    }
                </div>
            </div>

            <div className='card__content'>
                <Form className='form_features'>
                    {var_mode === 'VIEW' &&
                    <>
                        {feature_list.map(feature => {
                            let active_feature = var_features.find(item => item.name === feature.feature);
                            return (
                            <React.Fragment key={feature.name}>
                                <FORM_TOGGLE
                                    label={feature.name}
                                    value={active_feature?.status}
                                    options={[{ value: 'ACTIVE', text: 'On' }, { value: 'INACTIVE', text: 'Off' }]}
                                    disabled
                                    description={feature.info}
                                />
                                {active_feature?.status === 'ACTIVE' && feature.sub_features &&
                                    <div className='detailsgroup__subgroup rounded-lg'>
                                        {feature.sub_features.map(sub_feature => {
                                            let active_sub_feature = var_features.find(item => item.name === sub_feature.feature);
                                            return (
                                                <div className='detailsgroup__subgroup__item' key={`${feature.name}_${sub_feature.feature}`}>
                                                    <CHECKBOX
                                                        name={sub_feature.feature}
                                                        checked={active_sub_feature?.status === 'ACTIVE'}
                                                        disabled />
                                                    <div className='detailsgroup__subgroup__item__labeltwoline'>
                                                        <div className='text--sm-medium'>{sub_feature.name}</div>
                                                        <div className='text--sm-regular'>{sub_feature.info}</div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </React.Fragment>
                            )
                        })}
                    </>
                    }
                    {var_mode === 'EDIT' &&
                    <>
                        {feature_list.map(feature => {
                            let active_feature = var_working_features.find(item => item.name === feature.feature);
                            return (
                                <React.Fragment key={feature.name}>
                                    <FORM_TOGGLE
                                        label={feature.name}
                                        value={active_feature?.status}
                                        options={[{ value: 'ACTIVE', text: 'On' }, { value: 'INACTIVE', text: 'Off' }]}
                                        onChange={() => onChange_feature(feature.feature)}
                                        description={feature.info}
                                    />
                                    {active_feature?.status === 'ACTIVE' && feature.sub_features &&
                                        <div className='detailsgroup__subgroup rounded-lg'>
                                            {feature.sub_features.map(sub_feature => {
                                                let active_sub_feature = var_working_features.find(item => item.name === sub_feature.feature);
                                                return (
                                                    <div className='detailsgroup__subgroup__item' key={`${feature.name}_${sub_feature.feature}`}>
                                                        <CHECKBOX
                                                            name={sub_feature.feature}
                                                            checked={active_sub_feature?.status === 'ACTIVE'}
                                                            onChange={() => onChange_feature(sub_feature.feature)}
                                                        />
                                                        <div className='detailsgroup__subgroup__item__labeltwoline'>
                                                            <div className='text--sm-medium'>{sub_feature.name}</div>
                                                            <div className='text--sm-regular'>{sub_feature.info}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </React.Fragment>)
                        })}
                        <div className='card__footer'>
                            <Button className='primary' onClick={onClick_save}>Save</Button>
                            <Button className='secondary' onClick={onClick_cancel}>Cancel</Button>
                        </div>
                    </>
                    }
                </Form>
                <Processing display={var_processing}></Processing>
            </div>
        </>
    );
};
